import React, { Component } from 'react';
import GigsComponents from "./GigsComponents"
import axios from "axios";
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: null,
      links: null,
    };
  };


  randomLayer = {
    initialPosition: [
      {left: "0vw",top: "0vw"},
      {left: "0vw",top: "0vw"},
      {left: "0vw",top: "0vw"},
      {left: "0vw",top: "0vw"},
      {left: "0vw",top: "0vw"},
      {left: "0vw",top: "0vw"}
    ],
    movingPosition: [
      {left: "1vw", top: "0.5vw"},
      {left: "1.3vw",top: "0.7vw"},
      {left: "3.4vw",top: "1.5vw"},
      {left: "2.2vw",top: "0.5vw"},
      {left: "-1.4vw",top: "0.3vw"},
      {left: "1.3vw",top: "-1.7vw"},
    ]
  };


  componentDidMount(){

    axios.get("https://sheet.best/api/sheets/8a72d66a-5c0b-421d-a962-22d4ac672346")
      .then((response) => {
        const data = response.data;
        return data;
      })
      .then((data) => {
        let menu = data.filter((ele) => { return ele.type === "menu"});
        let links = data.filter((ele) => { return ele.type === "links"});
        this.setState({
          menu,
          links,
        })
      })
      .catch(function (error) {
        console.log(error);
      });
  };

 
  displayMenu = () => {
      if(!this.state.menu){
        return null;
      }

      let renderMenuSpan = this.state.menu.map((ele, index) => {
        return (
          <span key={index}>
            <a href={ele.links}>
              {ele.name}
            </a>
          </span>
        )
      });

      return (
        <nav className="menu_spans">
          {renderMenuSpan}
        </nav>
      )
  };


  generateParentComponents = (data) => {
    return data
    .map((ele, index) => {
      return (
        <GigsComponents 
          data={ele.name}
          link={ele.links}
          randomLayer={this.randomLayer}
        />
      )
    })
  }

  render() {
    
    const {links} = this.state;
    if(!links ){return null;}

    return (
      <main id="main_wrapper">
      <div id="menu">
          {this.displayMenu()}
      </div>
      <div className="gigs_container_master">
          {this.generateParentComponents(links)}
      </div>
      </main>
    )
  }
}

export default App;